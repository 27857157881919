// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-index-mdx": () => import("./../../src/index.mdx" /* webpackChunkName: "component---src-index-mdx" */),
  "component---src-kb-201901-data-is-not-showing-up-mdx": () => import("./../../src/kb/201901-data-is-not-showing-up.mdx" /* webpackChunkName: "component---src-kb-201901-data-is-not-showing-up-mdx" */),
  "component---src-kb-201902-how-to-resolve-problems-in-an-already-closed-report-mdx": () => import("./../../src/kb/201902-how-to-resolve-problems-in-an-already-closed-report.mdx" /* webpackChunkName: "component---src-kb-201902-how-to-resolve-problems-in-an-already-closed-report-mdx" */),
  "component---src-kb-202001-cannot-login-mdx": () => import("./../../src/kb/202001-cannot-login.mdx" /* webpackChunkName: "component---src-kb-202001-cannot-login-mdx" */),
  "component---src-kb-202002-reset-cache-mdx": () => import("./../../src/kb/202002-reset-cache.mdx" /* webpackChunkName: "component---src-kb-202002-reset-cache-mdx" */),
  "component---src-kb-test-seite-mdx": () => import("./../../src/kb/test-seite.mdx" /* webpackChunkName: "component---src-kb-test-seite-mdx" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

