module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".md",".mdx"],"remarkPlugins":[[null,{"type":"yaml","marker":"-"}],null],"rehypePlugins":[null,null],"gatsbyRemarkPlugins":[],"defaultLayouts":{"default":"/home/vsts/work/1/s/node_modules/gatsby-theme-docz/src/base/Layout.js"}},
    },{
      plugin: require('../../node_modules/gatsby-plugin-react-helmet-async/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-theme-docz/gatsby-browser.js'),
      options: {"plugins":[],"themeConfig":{},"themesDir":"src","mdxExtensions":[".md",".mdx"],"docgenConfig":{},"menu":["Getting Started"],"mdPlugins":[],"hastPlugins":[],"ignore":["README.md"],"typescript":false,"ts":false,"propsParser":true,"props-parser":true,"debug":false,"native":false,"openBrowser":false,"o":false,"open":false,"open-browser":false,"root":"/home/vsts/work/1/s/.docz","base":"/","source":"./","src":"./","files":"**/*.{md,markdown,mdx}","public":"/public","dest":".docz/dist","d":".docz/dist","editBranch":"master","eb":"master","edit-branch":"master","config":"","title":"VPM Support","description":"VPM Support Pages and Application Documentation","host":"localhost","port":3000,"p":3000,"separator":"-","paths":{"root":"/home/vsts/work/1/s","templates":"/home/vsts/work/1/s/node_modules/docz-core/dist/templates","docz":"/home/vsts/work/1/s/.docz","cache":"/home/vsts/work/1/s/.docz/.cache","app":"/home/vsts/work/1/s/.docz/app","appPackageJson":"/home/vsts/work/1/s/package.json","gatsbyConfig":"/home/vsts/work/1/s/gatsby-config.js","gatsbyBrowser":"/home/vsts/work/1/s/gatsby-browser.js","gatsbyNode":"/home/vsts/work/1/s/gatsby-node.js","gatsbySSR":"/home/vsts/work/1/s/gatsby-ssr.js","importsJs":"/home/vsts/work/1/s/.docz/app/imports.js","rootJs":"/home/vsts/work/1/s/.docz/app/root.jsx","indexJs":"/home/vsts/work/1/s/.docz/app/index.jsx","indexHtml":"/home/vsts/work/1/s/.docz/app/index.html","db":"/home/vsts/work/1/s/.docz/app/db.json"}},
    }]
